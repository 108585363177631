import { browserTracingIntegration, replayIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import 'rc-time-picker/assets/index.css';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './i18n';
import './index.css';
import './polyfill';

const sentryConfig = {
  dsn: "https://25525398ca39d614f8146ed88559fc01@o4508065107673088.ingest.us.sentry.io/4508801900478464",
  integrations: [
    browserTracingIntegration(),
    replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['https://dev.iso-gestion.com', 'https://app.iso-gestion.com'],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
};

if (window.location.hostname !== 'localhost') {
  Sentry.init(sentryConfig);
}

ReactDOM.render(<App />, document.getElementById('root'));
